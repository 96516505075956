exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ai-development-tsx": () => import("./../../../src/pages/ai-development.tsx" /* webpackChunkName: "component---src-pages-ai-development-tsx" */),
  "component---src-pages-ai-service-tsx": () => import("./../../../src/pages/ai-service.tsx" /* webpackChunkName: "component---src-pages-ai-service-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-data-tsx": () => import("./../../../src/pages/data.tsx" /* webpackChunkName: "component---src-pages-data-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-tsx": () => import("./../../../src/pages/insights.tsx" /* webpackChunkName: "component---src-pages-insights-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-whitepapers-tsx": () => import("./../../../src/pages/whitepapers.tsx" /* webpackChunkName: "component---src-pages-whitepapers-tsx" */),
  "component---src-templates-whitepaper-tsx": () => import("./../../../src/templates/whitepaper.tsx" /* webpackChunkName: "component---src-templates-whitepaper-tsx" */)
}

