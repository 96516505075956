module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-translate-urls/gatsby-browser.js'),
      options: {"plugins":[],"translations":{"es":{"urls.services":"servicios","urls.careers":"trabaja-con-nosotros","urls.privacy":"politica-de-privacidad","urls.ai-service":"servicios/ia-generativa/servicios-de-ia-generativa","urls.ai-development":"servicios/ia-y-aprendizaje-automatico/servicios-y-soluciones-de-inteligencia-artificial-ia","urls.data":"servicios/ingenieria-de-datos/servicios-de-ingenieria-de-datos","urls.blogs":"insights"}},"defaultLocale":"es","prefix":"urls."},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
